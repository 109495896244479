import React, { useState,useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';
import {  useNavigate, useParams, Link  } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
    Button,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { baseUrl } from "../../../include.js";
const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Payments = ({ className, transfers, ...rest }) => {
  const classes = useStyles();
  const [transaction, setTransaction] = useState([]);
  const [total, setTotal] = useState([]);
  

const navigate = useNavigate();
const generateRpt = rpt => {
      
         axios
          .get(baseUrl+'/payments', {
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            })
          .then(response => {
           const transaction1 = response.data.transaction;
            const  total = response.data.sumpayment;
              
            setTransaction(transaction1)
          
            setTotal(total)
            
         
          })
          .catch(error => {
            console.log(error);
             //navigate("/");
          });
      };
  
useEffect(() => {
    generateRpt();
  }, []);


  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1000}>
          <Table>
            <TableHead>
              <TableRow>
              
                <TableCell>
                  Transfer Branch
                </TableCell>
                <TableCell>
                  Trans Code
                </TableCell>
                
                <TableCell>
                 Receiving Teller
                </TableCell>
                <TableCell>
                 Receiver Name
                </TableCell>
                 <TableCell>
                 Receiver Phone
                </TableCell>
                <TableCell>
                 Date Transfered
                </TableCell>
                <TableCell>
                 Received Date
                </TableCell>
                <TableCell>
                 Amount
                </TableCell>
                <TableCell>
                 Status
                </TableCell>
                <TableCell>
                 Details
                </TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
               {transaction.map(item => (
                <TableRow
                  hover
                  key={item.transaction_id}
                
                >
                  
                  <TableCell>
                  {item.transfer_branch_manager_name}
                  </TableCell>

                  <TableCell>
                     {item.transaction_code}
                  </TableCell>

                  <TableCell>
                    {item.name}
                  </TableCell>
                 
                  <TableCell>
                    {item.receiver_name}
                  </TableCell>
                  <TableCell>
                    {item.receiver_phone}
                  </TableCell>
                   <TableCell>
                    {item.created_at}
                  </TableCell>
                    <TableCell>
                    {item.status==0 ? 'Not Received':item.updated_at }
                   
                  </TableCell>
                  <TableCell>
                    {item.amount_paid}
                  </TableCell>
                   <TableCell>
                   {item.status==0 ? 
       'Not Paid'
      :'Paid'
         }
                  </TableCell>
                   <TableCell>
                  <Link to={"/app/view-transfer-details/"+item.transaction_id}>
                    <Button
          color="primary"
          variant="contained"
        >
        View
        </Button>
        </Link>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
             <TableHead>
              <TableRow>
              
                <TableCell>
                  Payments 
                </TableCell>
                <TableCell>
                 
                </TableCell>
                
                <TableCell>
                 
                </TableCell>
                <TableCell>
                 
                </TableCell>
                 <TableCell>
                 
                </TableCell>
                <TableCell>
                
                </TableCell>
                <TableCell>
                 
                </TableCell>
                <TableCell>
                 {total}
                </TableCell>
                <TableCell>
                
                </TableCell>
                <TableCell>
                 
                </TableCell>
                
              </TableRow>
            </TableHead>
          </Table>
        </Box>
      </PerfectScrollbar>
      
    </Card>
  );
};

Payments.propTypes = {
  className: PropTypes.string,
  transfers: PropTypes.array.isRequired
};

export default Payments;
