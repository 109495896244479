import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {   Link  } from 'react-router-dom';
import {
  Box,
  
  
  Button,
  Card,
  CardContent,
  
  CardHeader,
  Divider,
  Grid,
  
  makeStyles,Table,
  TableBody,
  TableCell,
  TableHead,
  
  TableRow,Typography,Container
} from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/Money';

const states = [
  {
    value: 'alabama',
    label: 'Alabama'
  },
  {
    value: 'new-york',
    label: 'New York'
  },
  {
    value: 'san-francisco',
    label: 'San Francisco'
  }
];

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, customers,teller,details, ...rest }) => {
  const classes = useStyles();
   const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

 

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };


  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader=""
          title="UMF Transfer"
        />
        <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
 <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={12}
          >
            <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
             Sender Details
            </Typography>

             <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
             Sender Name: {customers.sender_name}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
             Sender Phone: {customers.sender_phone}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
            Sending Teller: {teller.name}
            </Typography>
            
          </Grid>
          
        </Grid>
      
      </CardContent>
          </Grid>


           <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={12}
          >
            <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
             Receiver Details
            </Typography>

             <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
             Receiver Name: {customers.receiver_name}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
             Receiver Phone: {customers.receiver_phone}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
            Receiving Teller: {customers.name}
            </Typography>
            
          </Grid>
          
        </Grid>
      
      </CardContent>
          </Grid>
        
       
        </Grid>
      </Container>
      <Divider />
      
    </Card>
        <Divider />
        <CardContent>
          <PerfectScrollbar>
        <Box minWidth={750}>
          <Table>
            <TableHead>
              <TableRow>
              
                
                <TableCell>
                 Details
                </TableCell>
                <TableCell>
                 Amount
                </TableCell>
               
                <TableCell>
                  Payment Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
             
                <TableRow
                  hover
                  key={customers.id}
                  
                >
                 
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                       Amount Paid
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                      {customers.symbol} {customers.amount_paid}
                  </TableCell>
                  
                 
                  <TableCell>
                   {details.payment_method_1 } {details.transfer_method_one } || {details.payment_method_2 } {details.transfer_method_two }
                  </TableCell>
                </TableRow>
              <TableRow
                  hover
                  key={customers.id}
                  
                >
                 
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                       Transaction Fee(Cash)
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                      {customers.symbol} {customers.transaction_amount}
                  </TableCell>
                  
                 
                  <TableCell>
                   {details.fee_method_one } {details.tamount_one } || {details.fee_method_two } {details.tamount_two }
                  </TableCell>
                </TableRow>
                <TableRow
                  hover
                  key={customers.id}
                  
                >
                 
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                       Amount Due
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                   <Box
                      alignItems="center"
                      display="flex"
                    >
                      
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {customers.symbol} {customers.due_amount}
                      </Typography>
                    </Box>
                  </TableCell>
                  
                 
                  
                </TableRow>
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => window.print()}
          >
            Print Invoice
          </Button>

          || 
<Link to="/app/transaction">
          <Button
            color="primary"
            variant="contained"
           
          >
            NEW TRANSACTION
          </Button>
          </Link>
        </Box>


      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
