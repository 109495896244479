import axios from 'axios';
export const baseUrl = "http://admin.unitransmoney.com/api/auth";

export const register = newUser => {
    return axios
        .post('api/users', newUser, {
            headers: { 'Content-Type':'application/json' }
        })
        .then(response => {
            console.log(response)
        })
        .catch(err => {
            console.log(err)
        })
}

export const login = user => {
    return axios
        .post(baseUrl+
            '/login',
            {
                email: user.email,
                password: user.password
            },
            {
                headers: { 'Content-Type':'application/json' }
            }
        )
        .then(response => {
            localStorage.setItem('usertoken', response.data.token)
            return response.data.token
            //console.log(response.data.token)
        })
        .catch(err => {
            console.log(err)
        })
}

export const getProfile = () => {
    return axios
        .get(baseUrl+'/user-profile', {
            headers: { Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            console.log(response)
            return response.data
        })
        .catch(err => {
            console.log(err)
        })
}

export const selectStyles = {
      menu: base => ({
        ...base,
        zIndex: 100
      })
    };

