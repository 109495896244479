import React, { useState,useEffect } from 'react';
import {BrowserRouter, useHistory} from 'react-router';
import axios from 'axios';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
   useNavigate,
    useLocation
  } from "react-router-dom";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import Page from 'src/components/Page';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import {CardHeader,Divider,Avatar,Box,Card,Container,CardContent,TextField,
  InputAdornment,Button,Checkbox,Table,TableBody,TableCell,TableHead,TablePagination,TableRow,Typography,
  makeStyles,Grid} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import Select from 'react-select';
import { baseUrl } from "../../../include.js";
const useStyles = makeStyles((theme) => ({
  root: {
  
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const AddUser = ({ className, customers, ...rest }) => {
  const classes = useStyles();

   const initialFormState = { branch_id: null, name: '',email:'',username:'',password:'',country:'',phone:'',branch_id:'',user_type:'',joining_date:''}
    
    const [user, setUser] = useState(initialFormState)

    const navigate = useNavigate();
    const [country, setCountry] = useState([]);
    const [countrys, setCountrys] = useState([]);
     const [branch, setBranch] = useState([]);
    const [branchs, setBranchs] = useState([]);

    const options = [
  { value: '1', label: 'Admin' },
  { value: '2', label: 'Agent' },
  { value: '3', label: 'Manager' },
];
const [userType, setuserType] = useState(null);

const handleType=(selectedValue)=>{
            console.log(selectedValue.value); 
            setuserType(selectedValue.value);
        }
   const handleChange = event => {
        const { name, value } = event.target
        setUser({ ...user, [name]: value })
      }

  const handleBranch=(selectedValue)=>{
            console.log(selectedValue.value); 
            setBranch(selectedValue.value);
        }

          const handleCountry=(selectedValue)=>{
            console.log(selectedValue.value); 
            setCountry(selectedValue.value);
        }
    
      const addUsers = user => {
       
        let obj = {
          name:user.name,
           username:user.username,
           email:user.email,
           phone:user.phone,
            country_id:country ,
            branch:branch,
            user_type:userType,
             joining_date:user.joining_date,
              password:user.password
        };
      
         axios
          .post(baseUrl+"/register", obj,{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            })
          .then(response => {
           
            setUser(initialFormState);
            alert("Information Saved");
            
            navigate("/app/security");
            
          })
          .catch(error => {
            console.log(error);
          });
      };

      const CountryMain=()=> {  
                return (countrys.map(data => ({ label: data.country_name, value: data.country_id})))  
        }

        const BranchMain=()=> {  
                return (branchs.map(data => ({ label: data.branch_name, value: data.branch_id})))  
        }
  
  useEffect(() => {
    async function getCountry() {
      const response = await fetch(baseUrl+"/country",{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            });
      
      const countryList = await response.json();
      //console.log(localStorage.usertoken);
      setCountrys(countryList);
    }
     async function getBranch() {
      const response = await fetch(baseUrl+"/branches",{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            });
      const branchList = await response.json();
      //console.log(localStorage.usertoken);
      setBranchs(branchList);
    }
    getCountry();
     getBranch();
  }, []);
  
  return (

    <Page
      className={classes.root}
      title="Users"
    >
      <Container maxWidth={false}>
       
        <Box mt={3}>
           <Card
      
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>


 <CardContent >


<form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}

      onSubmit={event => { event.preventDefault();
                            addUsers(user)}}>
      <Card  style={{minHeight:'500px'}}>
        <CardHeader
          title="Add New User"
        />
        <Divider />
        <CardContent >
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                size="small"
                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={user.name}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Joining Date"
                name="joining_date"
                size="small"
                type="date"
                onChange={handleChange}
                required
                value={user.joining_date}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
           
            
           
            
          </Grid>
        </CardContent>
       
      
        <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
               size="small"
                label="Phone"
                name="phone"
                onChange={handleChange}
                required
                value={user.phone}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                size="small"
                label="Email Address"
                name="email"
                onChange={handleChange}
                required
                value={user.email}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
           
            
           
            
          </Grid>
        </CardContent>
        
        <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
               size="small"
                label="User Name"
                name="username"
                onChange={handleChange}
                required
                value={user.username}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Password"
                name="password"
                type = "password"
                onChange={handleChange}
                required
                size="small"
                value={user.password}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
           
            
           
            
          </Grid>
        </CardContent>
        <Divider />
         <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
            <Select
  id="user.country"
        //defaultValue={selectedOption}
        onChange={handleCountry}
        //options={items}
        options={CountryMain()}
      />

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
             <Select
       
       onChange={handleType}
        options={options}
      />
            </Grid>
           
            
           
            
          </Grid>
        </CardContent>
       
       
        <Divider />

         <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
               <Select
  id="user.branch"
   onChange={handleBranch}
    options={BranchMain()}
        //defaultValue={selectedOption}
       
        //options={items}
       
      />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
             <Button
            color="primary"
            variant="contained"
            className="btn-lg"
            type="submit"
          >
            Save User
          </Button>
            </Grid>
           
            
           
            
          </Grid>
        </CardContent>

       
      </Card>
    </form>





         </CardContent>
        </Box>




      </PerfectScrollbar>
     </Card>
        </Box>
      </Container>
    </Page>
   
  );
};

export default AddUser;
