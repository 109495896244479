import React, { useState,useEffect } from 'react';
import {BrowserRouter, useHistory} from 'react-router';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
   useNavigate,
    useLocation
  } from "react-router-dom";
import axios from 'axios';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import Page from 'src/components/Page';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Select from 'react-select';
import {
  CardHeader, Divider, Avatar, Box, Card,Container,CardContent,TextField,InputAdornment,
   Button,Checkbox,Table,TableBody,TableCell,TableHead,TablePagination,TableRow,Typography,
  makeStyles,Grid} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { baseUrl } from "../../../include.js";

const useStyles = makeStyles((theme) => ({
  root: {
   
    marginRight:'auto',
    maxWidth: '80%',
     minHeight:'406px'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  widthInput:{
    minWidth:'306px'
  }
}));

const AddBranch = ({ className, customers, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
 const [country, setCountry] = useState([]);
   const [items, setItems] = useState([]);
    const initialFormState = { branch_id: null, branch_name: '',country_id:''}
    
    const [branch, setBranch] = useState(initialFormState)

    const [mode, setMode] = useState([])

      const handleChange = event => {
        const { name, value } = event.target
    
        setBranch({ ...branch, [name]: value })

       
      }

const handleCountry=(selectedValue)=>{
           
            console.log(selectedValue.value); 
            setCountry(selectedValue.value);
        }
    
      const addBranches = branch => {
       
        let obj = {
            branch_name:branch.branch_name,
            country_id:country ,
        };
      
         axios
          .post(baseUrl+"/create-branches", obj,{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            })
          .then(response => {
           
            setBranch(initialFormState);
            alert("Information Saved");
            
            navigate("/app/branch");
            
          })
          .catch(error => {
            console.log(error);
          });
      };
  
  useEffect(() => {
    async function getCharacters() {
      const response = await fetch(baseUrl+"/country",{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            });
      const body = await response.json();
      //console.log(localStorage.usertoken);
      setItems(body);
    }
    getCharacters();
  }, []);

 const [selectedOption, setSelectedOption] = useState([]);
  
const ServiceMain=()=> {  
                return (items.map(data => ({ label: data.country_name, value: data.country_id})))  
        }  

  return (

    <Page
      className={classes.root}
      title="Branches"
      style={{ 
        backgroundImage: `url(${process.env.PUBLIC_URL + '/bgMain.jpg'})`,
         
      }}
    >
      <Container maxWidth={false}
      
      >
       
        <Box mt={3}>
           <Card
      
    >
      <PerfectScrollbar>
        <Box minWidth={750} >


 <CardContent >

          
  
      <Card style={{minHeight:'250px'}}>
        <CardHeader
          title="Add New Branch"
        />
       
     

 <Grid container spacing={2} >  
        <Divider />

         <CardContent minHeight={406}>
           <form className="" onSubmit={event => {
                                    event.preventDefault();
                                    addBranches(branch)
                                    
                                  }}>
          <Grid
            container
            spacing={3}
            
          >
            <Grid
              item
              md={9}
              xs={12}

            >
             

<Select
  id="branch.country_id"
      
        onChange={handleCountry}
          
        options={ServiceMain()}
      />



            </Grid>


            <Grid
              item
              md={9}
              xs={12}
            >
             <TextField
                fullWidth
                size="small"
               label="Branch Name"
                name="branch_name"
                onChange={handleChange}
                required
                value={branch.branch_name}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
             
            </Grid>
           
            
            <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Save Branch
          </Button>
        </Box>
            
          </Grid>
          </form>
        </CardContent>



       
        </Grid>
      </Card>
    



         </CardContent>
        </Box>




      </PerfectScrollbar>
     </Card>
        </Box>
      </Container>
    </Page>
   
  );
};

export default AddBranch;
