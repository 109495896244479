import React , { useState,useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';

import Page from 'src/components/Page';

import CompanyDetails from './CompanyDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CompanyIndex = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Company Info"
      style={{ 
        backgroundImage: `url(${process.env.PUBLIC_URL + '/bgMain.jpg'})`,
         
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
        
          <Grid
            item
            lg={10}
            md={6}
            xs={12}
          >
            <CompanyDetails />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CompanyIndex;
