import React, { useState,useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    useParams,
    Link,
    Redirect,
   useNavigate,
    useLocation
  } from "react-router-dom";
import {
  Avatar,
  Box,
    Button,
  Card,
  Checkbox,
   CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className,deleteUser, customers, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  
  const [filteredCustomers, setFilteredCustomers] = useState([]);

    useEffect(() => {
    setFilteredCustomers(
      customers.filter((customers) =>
        customers.username.includes(search)
      )
    );
  }, [search, customers]);



  
 
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };



  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
        <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search Users By Username"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  UserName
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                
                <TableCell>
                  Role
                </TableCell>
                <TableCell>
                 Country
                </TableCell>
                 <TableCell>
                 Branch
                </TableCell>
                <TableCell>
               Update
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCustomers.map((item) => (
                <TableRow
                  hover
                  key={item.user_id}
                  
                >
                  <TableCell>
                    
                        {item.username}
                     
                  </TableCell>
                  <TableCell>
                    
                        {item.name}
                     
                  </TableCell>
                  <TableCell>
                    {item.email}
                  </TableCell>
                  <TableCell>
                    
                    {item.user_type ==1? 'Admin'
:item.user_type ==2? 'Agent'
:'Manager'}
                  </TableCell>
                  <TableCell>
                    {item.country_name}
                  </TableCell>
                  <TableCell>
                    {item.branch_name}
                  </TableCell>
                   <TableCell>
                   <Link to={"/app/edit-user/"+item.user_id+"/"+item.username}>
                   <Button
          color="primary"
          variant="contained"
          type="button"
          
        >
         Update
        </Button>
        </Link>
 </TableCell>
  <TableCell>
                   <Button
          color="secondary"
          variant="contained"
          onClick={() => {if(window.confirm('Are you sure to delete this record?')){ deleteUser(item.user_id)};}}
        >
         Delete
        </Button>
       
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={customers.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
