import React, { useState,useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';
import {  useNavigate, useParams, Link  } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
    Button,
  Card,
  Checkbox,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
   TextField,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import getInitials from 'src/utils/getInitials';
import { baseUrl } from "../../../include.js";
const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Receivables = ({ className, receivables, ...rest }) => {
  const classes = useStyles();
  const [transaction, setTransaction] = useState([]);
  const [total, setTotal] = useState([]);
  const [fee, setFee] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  

const navigate = useNavigate();
const generateRpt = rpt => {
      
         axios
          .get(baseUrl+'/receivable', {
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            })
          .then(response => {
           const transaction1 = response.data.transactions;
            const  total = response.data.sumreceivable;
              const  fee1 = response.data.sumfee;
            setTransaction(transaction1)
            setFee(fee1)
            setTotal(total)
            
         
          })
          .catch(error => {
            console.log(error);
          });
      };
  
useEffect(() => {
    generateRpt();
  }, []);

  useEffect(() => {
    setFilteredCustomers(
      transaction.filter((transaction) =>
        transaction.transaction_code.includes(search)
      )
    );
  }, [search, transaction]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1000}>
         <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search Users By Transaction Code"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
          <Table>
            <TableHead>
              <TableRow>
              
                <TableCell>
                  Trans Code
                </TableCell>
                
                <TableCell>
                 Sending Teller
                </TableCell>
                <TableCell>
                 Receiver Name
                </TableCell>
                 <TableCell>
                 Receiver Phone
                </TableCell>
                <TableCell>
                 Date Transfered
                </TableCell>
                <TableCell>
                 Received Date
                </TableCell>
                <TableCell>
                 Amount
                </TableCell>
                <TableCell>
                 Details
                </TableCell>
                <TableCell>
                 Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
               {filteredCustomers.map((item) =>  (
                <TableRow
                  hover
                  key={item.transaction_id}
                
                >
                  
                

                  <TableCell>
                     {item.transaction_code}
                  </TableCell>

                  <TableCell>
                    {item.name}
                  </TableCell>
                 
                  <TableCell>
                    {item.receiver_name}
                  </TableCell>
                  <TableCell>
                    {item.receiver_phone}
                  </TableCell>
                   <TableCell>
                    {item.created_at}
                  </TableCell>
                    <TableCell>
                   {item.status==0 ? 'Not Received':item.updated_at }
                  </TableCell>
                  <TableCell>
                    {item.amount_paid}
                  </TableCell>
                   <TableCell>
                    {item.status==0 ? 
       'Not Paid'
      :'Paid'
         }
                  </TableCell>
                   <TableCell>
                    <Link to={"/app/pay-cash/"+item.transaction_id}>
                   <Button
          color="primary"
          variant="contained"
        >
         Pay Cash
        </Button>
         </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
             <TableHead>
              <TableRow>
              
                <TableCell>
                  Receivables
                </TableCell>
                <TableCell>
                 
                </TableCell>
                
                <TableCell>
                 
                </TableCell>
                <TableCell>
                 
                </TableCell>
                 <TableCell>
                 
                </TableCell>
                <TableCell>
                
                </TableCell>
                <TableCell>
                 
                </TableCell>
                <TableCell>
                 {total}
                </TableCell>
                <TableCell>
                
                </TableCell>
                <TableCell>
                 
                </TableCell>
                
              </TableRow>
            </TableHead>
          </Table>
        </Box>
      </PerfectScrollbar>
      
    </Card>
  );
};

Receivables.propTypes = {
  className: PropTypes.string,
  receivable: PropTypes.array.isRequired
};

export default Receivables;
