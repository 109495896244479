import React,  { useState,useEffect }  from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import AgentReport from './AgentReport';
import ManagerReport from './ManagerReport';
import data from './data';
import axios from 'axios';
import { baseUrl } from "../../../../include.js";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AllTransfer = () => {
  const classes = useStyles();
  const [customers] = useState(data);

   const [profile, setProfile] = useState([]);


  const getProfile = () => {
    return axios
        .get(baseUrl+'/user-profile', {
            headers: { Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            console.log(response.data)
            const pro1 = response.data;
           setProfile(pro1);
         })
        .catch(err => {
            console.log(err)
        });
}

useEffect(() => {
     getProfile();
  }, []);
  
  return (
    <Page
      className={classes.root}
      title="Users"
      style={{ 
        backgroundImage: `url(${process.env.PUBLIC_URL + '/bgMain.jpg'})`,
         
      }}
    >
      <Container maxWidth={false}>
     
      {profile.user_type == 1 ? (
    <Toolbar customers={customers}/>
) : profile.user_type  == 3 ? (
     <ManagerReport customers={customers}/>
): (
   
    <AgentReport customers={customers}/>
)}

        
        
      </Container>
    </Page>
  );
};

export default AllTransfer;
