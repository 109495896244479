import React, { useState,useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
   useNavigate
  } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import { baseUrl } from "../../../include.js";

const useStyles = makeStyles(() => ({
  root: {}
}));

const CompanyDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState([]);
    
    const [company, setCompany] = useState("")

    const navigate = useNavigate();


   const handleChange = event => {
        const { name, value } = event.target
        setCompany({ ...company, [name]: value })
      }

       const fetchCompany = () =>{
         
          axios.get(baseUrl+"/company-info",{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            }).then(response=>{
            
             
                const comm = response.data.company;
                setCompany(comm);

                console.log(company)
                 
              
            });
  }

    
    
      const addCompany = company => {
       
        let obj = {
          companyName:company.companyName,
          companyAddress:company.companyAddress,
          companyEmail:company.companyEmail,
          companyPhone:company.companyPhone,
          companyCountry:company.companyCountry,
          headOffice:company.headOffice,
        };
      
         axios
          .post(baseUrl+"/company-info", obj,{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            })
          .then(response => {
           
            
            alert("Information Saved");
            
            navigate("/app/company");
            
          })
          .catch(error => {
            console.log(error);
          });
      };


      useEffect(() => {
  
     fetchCompany();
  }, []);

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}

        onSubmit={event => { event.preventDefault();
                            addCompany(company)}}
    >
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title="Compnay Info"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="Name of Company"
                name="companyName"
                onChange={handleChange}
                required
                value={company.companyName}
                variant="outlined"
                 InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Address"
                name="companyAddress"
                id = "companyAddress"
                onChange={handleChange}
                required
                value={company.companyAddress}
                variant="outlined"
                 InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email Address"
                name="companyEmail"
                id = "companyEmail"
                onChange={handleChange}
                required
                value={company.companyEmail}
                variant="outlined"
                 InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Phone Number"
                name="companyPhone"
                id = "companyPhone"
                onChange={handleChange}
                type="text"
                value={company.companyPhone}
                variant="outlined"
                 InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Country"
                name="companyCountry"
                id = "companyCountry"
                onChange={handleChange}
                required
                value={company.companyCountry}
                variant="outlined"
                 InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Head Office Location"
                name="headOffice"
                id = "headOffice"
                onChange={handleChange}
                required
                value={company.headOffice}
                variant="outlined"
                 InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            type = "submit"
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

CompanyDetails.propTypes = {
  className: PropTypes.string
};

export default CompanyDetails;
