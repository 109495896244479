import React,{ useState,useEffect }  from 'react';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TransferMoney from './TransferMoney.js';
import Transfers from './Transfers.js';
import Payments from './Payments.js';
import Receivables from './Receivables.js';
import { baseUrl } from "../../../include.js";
import {
  Card,
  Box,
  Container,
  CardContent,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TransactionView = ({ className, ...rest }) => {
  const classes = useStyles();
  const [transfers,setTransfers] = useState("");
  const [receivables,setReceivables] = useState("");
  const [payments,setPayments] = useState("");
 const [customers,setCustomers] = useState("");
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };
   useEffect(() => {
        const fetchTransfer = () =>
          fetch(baseUrl+"/transfers",{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            })
            .then(res => res.json())
            .then(data => {
              const transfers = data.transactions;
                const sum_total = data.sum;
               setTransfers(transfers)
              
            })

             const fetchReceivables = () =>
          fetch(baseUrl+"/receivable",{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            })
            .then(res => res.json())
            .then(data => {
              const receivables = data.transactions;
                const sum_total = data.sum;
               setTransfers(transfers)
              
            })
        fetchTransfer();
        fetchReceivables();
      }, []);

  return (
    <Page
      className={classes.root}
      title="Users"
      style={{ 
        backgroundImage: `url(${process.env.PUBLIC_URL + '/bgMain.jpg'})`,
         
      }}
    >
      <Container maxWidth={false}>
       
        <Box mt={3}>
           <Card
      
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
 <CardContent>
<TabContext value={value}>
       
          <TabList onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Transfer Money" value="1" />
            <Tab label="Transfers" value="2" />
            <Tab label="Receivables" value="3" />
             <Tab label="Payments" value="4" />
          </TabList>
     
        <TabPanel value="1"><TransferMoney /></TabPanel>

        <TabPanel value="2"><Transfers transfers={transfers}/></TabPanel>

        <TabPanel value="3"><Receivables receivables={receivables}/></TabPanel>

        <TabPanel value="4"><Payments payments={payments}/></TabPanel>
      </TabContext>

         </CardContent>
        </Box>
      </PerfectScrollbar>
     </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default TransactionView;
