import React, { useState,useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import axios from 'axios';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import data from './data';
import { baseUrl } from "../../../include.js";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const UserListView = () => {
  const classes = useStyles();
  const [customers,setCustomer] = useState([]);

  

   useEffect(() => {
        const fetchMode = () =>
          fetch(baseUrl+"/users",{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            })
            .then(res => res.json())
            .then(data => {
               setCustomer(data)
              
            })
        fetchMode()
      }, []);
   

   const deleteUser = user_id => {
    //setEditing(false)
      setCustomer(customers.filter(customer => customer.user_id !== user_id))
    
      axios.delete(baseUrl+"/delete-users/"+user_id ,{ 
       headers: { Authorization: `Bearer ${localStorage.usertoken}`}
        })
                .then(
                console.log(customers.user_id),
               
       
                  )
                .catch(err => console.log(err))
      
    }


  return (
    <Page
      className={classes.root}
      title="Users"
      style={{ 
        backgroundImage: `url(${process.env.PUBLIC_URL + '/bgMain.jpg'})`,
         
      }}
    >
      <Container maxWidth={false}>
      
        <Box mt={3}>
          <Results customers={customers}  deleteUser = {deleteUser}/>
        </Box>
      </Container>
    </Page>
  );
};

export default UserListView;
