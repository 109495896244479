import React, { useState,useEffect } from 'react';
import {BrowserRouter, useHistory} from 'react-router';
import axios from 'axios';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
  useNavigate,
    useLocation
   
  } from "react-router-dom";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  CardHeader,
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,Grid,Divider
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import Select from 'react-select';
import { baseUrl } from "../../../../include.js";
import { selectStyles } from "../../../../include.js";
const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '10%',
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));


const Toolbar = ({ className,props,customers, ...rest }) => {
  const classes = useStyles();
let postUrl = baseUrl+"/all-transfer-report";
  const initialFormState = { rptID: null, rptType: '',rptFromDate:'',rptToDate:'',rptCurrency:'',rptAgent:'',branch:''}
    
    const [rpt, setRpt] = useState(initialFormState)

    const navigate = useNavigate();
    const [currency, setCurrency] = useState([]);
    const [currencys, setCurrencys] = useState([]);

    const [agent, setAgent] = useState(0);
    const [agents, setAgents] = useState([]);
    const [url, setUrl] = useState("");

 const [branch, setBranch] = useState([]);
    const [branchs, setBranchs] = useState([]);

     const [report, setReport] = useState([]);
   

    const options = [
  { value: 'all-transfer-report', label: 'All Transfer Report' },
  { value: 'all-receivable-report', label: 'All Receivable Report' },
  { value: 'all-payment-report', label: 'All Payment Report' },
  { value: 'transfer-method-report', label: 'Transfer Method Report' },
   { value: 'payment-method-report', label: 'Payment Method Report' },
    { value: 'transaction-fee', label: 'Transaction Fee Report' },
    { value: 'all-reverse-report', label: 'Reversed Transaction Report' },
     { value: 'highest-transfer-report', label: 'Highest Transfer Report' },
];
const [userType, setuserType] = useState(null);

const handleType=(selectedValue)=>{
            console.log(selectedValue.value); 
            setuserType(selectedValue.value);
        }
   const handleChange = event => {
        const { name, value } = event.target
        setRpt({ ...rpt, [name]: value })
      }

  const handleReport=(selectedValue)=>{
            console.log(selectedValue.value); 
            setReport(selectedValue.value);
             setUrl(baseUrl+"/" + selectedValue.value);
            
            console.log(url); 

        }

      const handleCurrency=(selectedValue)=>{
            console.log(selectedValue.value); 
            setCurrency(selectedValue.value);
        }

         const handleAgent=(selectedValue)=>{
            console.log(selectedValue.value); 
            setAgent(selectedValue.value);
        }

        const handleBranch=(selectedValue)=>{
            console.log(selectedValue.value); 
            setBranch(selectedValue.value);
        }

        
    
      const generateRpt = rpt => {
       
        let obj = {
          type:report,
           
           from:rpt.rptFromDate,
           to:rpt.rptToDate,
            user:agent,
            currency:currency ,
            branch:branch 
           
        };
      
         axios
          .post(url, obj,{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            })
          .then(response => {
           
            setRpt(initialFormState);
          
            
          navigate('/app/'+report+'/'+rpt.rptFromDate+'/'+rpt.rptToDate+'/'+agent+'/'+currency

          )
          })
          .catch(error => {
            console.log(error);
          });
      };

      const CurrencyMain=()=> {  
                return (currencys.map(data => ({ label: data.currency_name, value: data.currency_id})))  
        }

        const AgentMain=()=> {  
                return (agents.map(data => ({ label: data.username, value: data.user_id})))  
        }

         const BranchMain=()=> {  
                return (branchs.map(data => ({ label: data.branch_name, value: data.branch_id})))  
        }
  
  useEffect(() => {
    async function getCurrency() {
      const response = await fetch(baseUrl+"/currency",{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            });
      const currencyList = await response.json();
      //console.log(localStorage.usertoken);
      setCurrencys(currencyList);
    }
     async function getAgent() {
      const response = await fetch(baseUrl+"/users",{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            });
      const agentList = await response.json();
      //console.log(localStorage.usertoken);
      setAgents(agentList);
    }
    async function getBranch() {
      const response = await fetch(baseUrl+"/branches",{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            });
      const branchList = await response.json();
      //console.log(localStorage.usertoken);
      setBranchs(branchList);
    }
    getBranch();
    getCurrency();
     getAgent();
  }, []);
  

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
      
    >
     
      <Box mt={3} >
        <Card >
          <CardContent>
            <Box maxWidth={1000} maxHeight={480}
            
>
              
<form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}

      onSubmit={event => { event.preventDefault();
                            generateRpt(rpt)}}>
      <Card  style={{minHeight:'500px'}}>
        <CardHeader
          title="Generate Report"
        />
        <Divider />
       
 <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
               <Select
  id="rptType"
  
        placeholder="Select Report Type"
        onChange={handleReport}
        options={options}
        styles={selectStyles}
      />
            </Grid>
           
           
           </Grid>
        </CardContent>
       <Divider />
       
      
       
        
        <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
               size="small"
                label="Date From"
                name="rptFromDate"
                onChange={handleChange}
               required={true}
                 type="date"
                InputLabelProps={{
            shrink: true,
          }}
               id = "rptFromDate"
                
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
            
              <TextField
                fullWidth
                label="Date To"
                name="rptToDate"
                id="rptToDate"
                type="date"
                onChange={handleChange}
                required = {true}
                size="small"
               InputLabelProps={{
            shrink: true,
          }}
                variant="outlined"
              />

            </Grid>
           
            
           
            
          </Grid>
        </CardContent>
        <Divider />
         <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
            <Select
  id="rptCurrency"
        placeholder="Select Currency"
        onChange={handleCurrency}
     styles={selectStyles}
        options={CurrencyMain()}
      />

            </Grid>
             <Grid
              item
              md={6}
              xs={12}
            >
             <Select
             placeholder="Select Teller"
       styles={selectStyles}
       onChange={handleAgent}
        options={AgentMain()}
        id="rptAgent"
        defaultValue = {0}
      />
            </Grid>
            
           
            
           
            
          </Grid>
        </CardContent>
       
       
        <Divider />

         <CardContent>
           <Grid
            container
            spacing={4}
          >
         


        
           
            <Grid
              item
              md={6}
              xs={10}
            >
             <Button
            color="primary"
            variant="contained"
            className="btn-lg"
            type="submit"

          >
            Save User
          </Button>
            </Grid>
           
            
           
            
          </Grid>
        </CardContent>

       
      </Card>
    </form>

            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
