import React from 'react';

import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import DashboardView from 'src/views/reports/DashboardView';
import DatabaseView from 'src/views/database/DatabaseView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import CompanyView from 'src/views/company/CompanyView';
import BranchListView from 'src/views/branch/BranchListView';
import AddBranch from 'src/views/branch/BranchListView/addBranch';
import CurrencyListView from 'src/views/currency/CurrencyListView';
import AddCurrency from 'src/views/currency/CurrencyListView/addCurrency';
import UserListView from 'src/views/security/UserListView';
import AddUser from 'src/views/security/UserListView/addUser';
import EditUser from 'src/views/security/UserListView/editUser';
import TransactionView from 'src/views/transaction/TransactionView';
import InvoiceView from 'src/views/invoicing/InvoiceView';
import AllTransfer from 'src/views/reports/MainReport/ReportView';
import AllTransferRpt from 'src/views/reports/MainReport/ReportView/alltransferrpt';
import AllReceiverRpt from 'src/views/reports/MainReport/ReportView/allreceiverrpt';
import AllPaymentRpt from 'src/views/reports/MainReport/ReportView/allpaymentrpt';

import PeriodicTransferRpt from 'src/views/reports/MainReport/ReportView/periodictransferrpt';
import PeriodicReceiverRpt from 'src/views/reports/MainReport/ReportView/periodicreceiverrpt';
import PeriodicPaymentRpt from 'src/views/reports/MainReport/ReportView/periodicpaymentrpt';

import TransferMethodRpt from 'src/views/reports/MainReport/ReportView/transfermethodrpt';
import PaymentMethodRpt from 'src/views/reports/MainReport/ReportView/paymentmethodrpt';
import TransferFeeRpt from 'src/views/reports/MainReport/ReportView/transferfeerpt';
import HighestTransferRpt from 'src/views/reports/MainReport/ReportView/highesttransferrpt';
import ViewTransferDetails from 'src/views/transaction/TransactionView/viewTransferDetails';
import PayCash from 'src/views/transaction/TransactionView/PayCash';
import PaymentReceipt from 'src/views/transaction/TransactionView/paymentReceipt';
import { Navigate } from 'react-router-dom';
const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
    { path: '/company', element: <CompanyView /> },
      { path: '/branch', element: <BranchListView /> },
     { path: '/add-branch', element: <AddBranch /> },
      { path: '/currency', element: <CurrencyListView /> },
      { path: '/add-currency', element: <AddCurrency /> },
      { path: '/security', element: <UserListView /> },
       { path: '/add-user', element: <AddUser /> },
       { path: '/edit-user/:id/:name', element: <EditUser /> },
      { path: '/transaction', element: <TransactionView /> },
       { path: '/invoice', element: <InvoiceView /> },
      { path: '/dashboard', element: <DashboardView /> },
       { path: '/database', element: <DatabaseView /> },
      { path: '/report', element: <AllTransfer/> },

      { path: '/all-transfer-report/:from/:to/:agent/:currency/', element: <AllTransferRpt/> },
      { path: '/all-receivable-report/:from/:to/:agent/:currency/', element: <AllReceiverRpt/> },
       { path: '/all-payment-report/:from/:to/:agent/:currency/', element: <AllPaymentRpt/> },
       { path: '/transfer-method-report/:from/:to/:agent/:currency/', element: <TransferMethodRpt/> },
        { path: '/payment-method-report/:from/:to/:agent/:currency/', element: <PaymentMethodRpt/> },

         { path: '/transaction-fee/:from/:to/:agent/:currency/', element: <TransferFeeRpt/> },
          { path: '/highest-transfer-report/:from/:to/:agent/:currency/', element: <HighestTransferRpt/> },
         

          { path: '/periodic-transfer-report/:from/:to/:currency/', element: <PeriodicTransferRpt/> },
      { path: '/periodic-receivable-report/:from/:to/:currency/', element: <PeriodicReceiverRpt/> },
       { path: '/periodic-payment-report/:from/:to/:currency/', element: <PeriodicPaymentRpt/> },



          { path: '/view-transfer-details/:id', element: <ViewTransferDetails/> },
           { path: '/pay-cash/:id', element: <PayCash/> },
           { path: '/payment-receipt/:id', element: <PaymentReceipt/> },

      { path: '/settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      //{ path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '/', element: <LoginView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
