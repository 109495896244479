import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';
//import bgImage from '/static/images/avatars/avatar_5.png';

const theme = createMuiTheme({
  palette: {
    background: {
     
      dark: '#F4F6F8',
      default: colors.common.blue,
      paper: colors.common.white
    },
    primary: {
      main: colors.green[900]
    },
    secondary: {
      main: colors.deepOrange[400]
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography
});

export default theme;
