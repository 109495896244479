import React, { useState,useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import {  useNavigate, useParams, Link  } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
   Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import getInitials from 'src/utils/getInitials';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className,deleteTransactions, customers, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  
  const [filteredCustomers, setFilteredCustomers] = useState([]);

    useEffect(() => {
    setFilteredCustomers(
      customers.filter((customers) =>
        customers.transaction_code.includes(search)
      )
    );
  }, [search, customers]);

  if (loading) {
    return <p>Loading customers...</p>;
  }

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1000}>
        <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search Transaction Code"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
          <Table>
            <TableHead>
              <TableRow>
              
                <TableCell>
                  Trans Code
                </TableCell>
                
                <TableCell>
                 Transfer Agent
                </TableCell>
                <TableCell>
                 Reciver Name
                </TableCell>
                
                <TableCell>
                 Date Transfered
                </TableCell>
                <TableCell>
                 Received Date
                </TableCell>
                <TableCell>
                 Amount
                </TableCell>
                <TableCell>
                 Status
                </TableCell>
                <TableCell>
                Action
                </TableCell>
                 <TableCell>
               Pay
                </TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCustomers.map((item) => (
                 <TableRow
                  hover
                  key={item.transaction_id}
                
                >
                  
                  

                  <TableCell>
                     {item.transaction_code}
                  </TableCell>

                  <TableCell>
                    {item.name}
                  </TableCell>
                 
                  <TableCell>
                    {item.receiver_name}
                  </TableCell>
                  
                   <TableCell>
                    {item.created_at}
                  </TableCell>
                    <TableCell>
                    {item.status==0 ? 'Not Received':item.updated_at }
                   
                  </TableCell>
                  <TableCell>
                   {item.symbol}  {item.amount_paid}
                  </TableCell>
                   <TableCell>
                   {item.status==0 ? 
       'Not Paid'
      :'Paid'
         }
                  </TableCell>
                   <TableCell>
              
                    <Button
          color="secondary"
          variant="contained"
           onClick={() =>{if(window.confirm('Are you sure to delete this record?')){ deleteTransactions(item.transaction_id)};}}
        >
        Delete
        </Button>
      

                  </TableCell>

                  <TableCell>
                    <Link to={"/app/pay-cash/"+item.transaction_id}>
                   <Button
          color="primary"
          variant="contained"
        >
         Pay Cash
        </Button>
         </Link>
           </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={customers.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
