import React, { useState,useEffect } from 'react';
import {BrowserRouter} from 'react-router';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
   useHistory,
    useLocation,
    useNavigate
  } from "react-router-dom";
import axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import { login } from "src/include.js";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
 

    const navigate = useNavigate();

    const initialFormState = { user_id:null, email : '', password:''}

    const initialValue = [{}];
     
      const [user, setUser] = useState(initialFormState)

      
    
      const handleInputChange = event => {
        const { name, value } = event.target
    
        setUser({ ...user, [name]: value })
      }

 const userLogin = user => {
        let obj = {     
            email: user.email ,
            password : user.password ,

        };
        login(user).then(res => {
            if (res) {
             navigate("/app/dashboard");
            }
        })
      };
      

  return (
    <Page
      className={classes.root}
      title="Login"
      style={{ 
        backgroundImage: `url(${process.env.PUBLIC_URL + '/umf1.jpg'})`,
         
      }}

     >
    
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
       
        
      >
        <Container maxWidth="sm" style = {{backgroundColor:'#fff'}}>
          <form
            
           onSubmit={event => {
                                    event.preventDefault();
                                
                                    if (!user.email || !user.password ) return
                                
                                    userLogin(user)
                                    
                                  }}
          >
            
              
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"

                  >
                    Sign in
                  </Typography>
                 
                </Box>
                <Grid
                  container
                  spacing={3}
                >
                  
                  
                </Grid>
               
                <TextField
                 
                  fullWidth
                  
                  label="Email Address"
                  margin="normal"
                  name="email"
                id = "email"
                  type="email"
                  onChange={handleInputChange}
                  value={user.email}
                  variant="outlined"
                />
                <TextField
                 
                  fullWidth
               
                  label="Password"
                  margin="normal"
                  name="password"
                  id="password"
                onChange={handleInputChange}
                  type="password"
                  value={user.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                   
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
               
           
         
          </form>
        </Container>
      </Box>
    </Page>
  );
};

export default (LoginView);
