import React, { useEffect,useState } from 'react';
import { Link as RouterLink,useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
 
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  
   Database as DatabaseIcon,
  Home as HomeIcon,
  MessageSquare as MessageSquareIcon,
  Columns as ColumnsIcon
} from 'react-feather';
import NavItem from './NavItem';
import axios from 'axios'
import { baseUrl } from "../../../include.js";

 

const user = {
  avatar: '/static/images/avatars/avatar_5.png',
  jobTitle: 'Senior Developer',
  name: 'King Jules'
};



const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
   const [profile,setProfile] = useState([]);

   const getProfile = () => {
    return axios
        .get(baseUrl+'/user-profile', {
            headers: { Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            setProfile(response.data);
           
        })
        .catch(err => {
            console.log(err)
             localStorage.clear();
            navigate("/");
        })
}
  useEffect(() => {
     getProfile();
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);



  const items = [
  {
    href: '/app/dashboard',
    icon: HomeIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/company',
    icon: SettingsIcon,
    title: 'Company Set Up'
    
  },
  {
    href: '/app/branch',
    icon: ColumnsIcon,
    title: 'Branches'
  },
  {
    href: '/app/security',
    icon: LockIcon,
    title: 'Security'
  },
  {
    href: '/app/currency',
    icon: ColumnsIcon,
    title: 'Currency'
  },
  {
    href: '/app/transaction',
    icon: ShoppingBagIcon,
    title: 'Transactions'
  },
  {
    href: '/app/database',
    icon: DatabaseIcon,
    title: 'Database'
  },
  {
    href: '/app/report',
    icon: BarChartIcon,
    title: 'Reporting'
  }
  
];

const userItems = [
   {
    href: '/app/dashboard',
    icon: HomeIcon,
    title: 'Dashboard'
  },
 
  {
    href: '/app/transaction',
    icon: ShoppingBagIcon,
    title: 'Transactions'
  },
  {
    href: '/app/report',
    icon: BarChartIcon,
    title: 'Reporting'
  }
  
];

const managerItems = [
   {
    href: '/app/dashboard',
    icon: HomeIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/security',
    icon: LockIcon,
    title: 'Security'
  },
  
 
  {
    href: '/app/transaction',
    icon: ShoppingBagIcon,
    title: 'Transactions'
  },
  {
    href: '/app/report',
    icon: BarChartIcon,
    title: 'Reporting'
  }
  
];




  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
     
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
         WELCOME
        </Typography>
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h6"
        >
          {profile.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
         {profile.branch_name} Branch
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
     {profile.user_type ==1?
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>:
        
        profile.user_type ==3?
        <List>
          {managerItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>:
        <List>
          {userItems.map((userItem) => (
            <NavItem
              href={userItem.href}
              key={userItem.title}
              title={userItem.title}
              icon={userItem.icon}
            />
          ))}
        </List>
      }
      </Box>
     
      
    </Box>
  );
  
  return (

    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >

       
   {content}
  
          
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
