import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    address: {
      country: 'USA',
      state: 'West Virginia',
      city: 'Parkersburg',
      street: '2849 Fulton Street'
    },
    avatarUrl: '/static/images/avatars/ghana.png',
    createdAt: 1555016400000,
    email: 'Accra',
    name: 'Ghana',
    phone: '304-428-3097'
  },
  {
    id: uuid(),
    address: {
      country: 'USA',
      state: 'Bristow',
      city: 'Iowa',
      street: '1865  Pleasant Hill Road'
    },
    avatarUrl: '/static/images/avatars/liberia.png',
    createdAt: 1555016400000,
    email: 'New Port',
    name: 'Liberia',
    phone: '712-351-5711'
  },
  {
    id: uuid(),
    address: {
      country: 'USA',
      state: 'Ohio',
      city: 'Dover',
      street: '4158  Hedge Street'
    },
    avatarUrl: '/static/images/avatars/liberia.png',
    createdAt: 1554930000000,
    email: 'Monrovia',
    name: 'Liberia',
    phone: '908-691-3242'
  }
];
