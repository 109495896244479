import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    address: {
      country: 'USA',
      state: 'West Virginia',
      city: 'Parkersburg',
      street: '2849 Fulton Street'
    },
    avatarUrl: '/static/images/avatars/avatar_3.png',
    status: 'Manager',
    email: 'jules@eazidon.com',
    name: 'King Jules',
     branch: 'Buduburam',
    country: 'Ghana'
  },
  {
    id: uuid(),
    address: {
      country: 'USA',
      state: 'Bristow',
      city: 'Iowa',
      street: '1865  Pleasant Hill Road'
    },
    avatarUrl: '/static/images/avatars/avatar_4.png',
     email: 'marlon@eazidon.com',
    status: 'Agent',
    name: 'Kwasi Marlon',
     branch: 'New Town',
    country: 'Liberia'
  },
  {
    id: uuid(),
    address: {
      country: 'USA',
      state: 'Bristow',
      city: 'Iowa',
      street: '1865  Pleasant Hill Road'
    },
    avatarUrl: '/static/images/avatars/avatar_5.png',
     email: 'admin@eazidon.com',
    status: 'Admin',
    name: 'Bob Macintosh',
     branch: 'Accra',
    country: 'Ghana'
  }
];
