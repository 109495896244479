import React, { useState,useEffect } from 'react';
import {BrowserRouter, useHistory} from 'react-router';
import axios from 'axios';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {  useNavigate, useParams, Link  } from 'react-router-dom';
import {
  Avatar,
  Box,
    Button,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
  makeStyles
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { baseUrl } from "../../../../include.js";
const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const TransferFeeRpt = ({ className, ...rest }) => {
  const [transaction, setTransaction] = useState([]);
  const [total, setTotal] = useState([]);
  const [fee, setFee] = useState([]);

    

  const classes = useStyles();
const navigate = useNavigate();
let { from } = useParams();
let { to } = useParams();
let { agent } = useParams();
let { currency } = useParams();


  
const generateRpt = rpt => {
       
        let obj = {
        
           
           from:from,
           to:to,
            user:agent,
            currency:currency ,
           
        };
      
         axios
          .post(baseUrl+'/transaction-fee', obj,{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            })
          .then(response => {
           const transaction1 = response.data.transaction;
            const  total1 = response.data.sumtransaction;
              const  fee1 = response.data.sumfee;
            setTransaction(transaction1)
            setFee(fee1)
            setTotal(total1)
            
         
          })
          .catch(error => {
            console.log(error);
          });
      };
  
useEffect(() => {
    generateRpt();
  }, []);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
    <Grid
              item
              md={10}
              xs={10}
            >
            <Link to="/app/report">
             <Button
            color="primary"
            variant="contained"
            className="btn-lg"
            type="submit"
         
          >
           Go  Back 
          </Button>
          </Link>
            </Grid>
          
      <PerfectScrollbar>
        <Box minWidth={1050}>
       

          <Table>
            <TableHead>
              <TableRow>
                
                <TableCell>
                  Transfer Date
                </TableCell>
                <TableCell>
                  Transaction Code
                </TableCell>
                
                <TableCell>
                  Payment Teller
                </TableCell>
                <TableCell>
                Sender Name
                </TableCell>
                 <TableCell>
                 Receiver Name
                </TableCell>
                  <TableCell>
                  Amount Transfered
                </TableCell>
                 <TableCell>
                 Payment Metod 1
                </TableCell>
                <TableCell>
                  Payment Metod 2
                </TableCell>
                 <TableCell>
                 Transaction Fee
                </TableCell>
                 <TableCell>
                Fee Payment 1 
                </TableCell>
                <TableCell>
                Fee Payment 2
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
           {transaction.map(item => (
                <TableRow
                  hover
                 
                  key = {item.transaction_id}
                >
                   <TableCell>
                   
                       {item.created_at}
                      
                  </TableCell>
                  <TableCell>
                   
                       {item.transaction_code}
                      
                  </TableCell>
                  <TableCell>
                   {item.SenderName}
                  </TableCell>
                  
                  <TableCell>
                   {item.sender_name}
                  </TableCell>
                  <TableCell>
                     {item.receiver_name}
                  </TableCell>
                   <TableCell>
                     {item.default_cur} {item.amount_paid}
                  </TableCell>
                   <TableCell>
                     {item.payment_method_1} {item.transfer_method_one}
                  </TableCell>
                   <TableCell>
                     {item.payment_method_2} {item.transfer_method_two}
                  </TableCell>
                   <TableCell>
                     {item.default_cur} {item.transaction_amount}
                  </TableCell>
                    <TableCell>
                     {item.fee_method_one} {item.tamount_one}
                  </TableCell>
                   <TableCell>
                    {item.fee_method_two} {item.tamount_two}
                  </TableCell>
                   
                </TableRow>
                ))}
             
            </TableBody>
           
          </Table>
        </Box>
      </PerfectScrollbar>
      
    </Card>
  );
};



export default TransferFeeRpt;
