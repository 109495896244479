import React, { useState,useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Budget from './Budget';
import Sales from './Sales';
import TasksProgress from './TasksProgress';
import TotalCustomers from './TotalCustomers';
import { baseUrl } from "../../../include.js";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();

  const [transfer, setTransfer] = useState([]);
    const [payment, setPayment] = useState([]);
   
    const [receivables, setReceivables] = useState([]);

     const [branch, setBranch] = useState([]);

  useEffect(() => {
    async function fetchStats() {
     axios.get(baseUrl+"/home",{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            }).then(items=>{
                const transfer1 = items.data.transfer;
                const receiver1 = items.data.receivable;
                const payment1 = items.data.payment;
                const branch1 = items.data.manager;
                setTransfer(transfer1);
                setPayment(payment1);
                setReceivables(receiver1);
                setBranch(branch1);
             
               
            });
    }
    

   
    fetchStats();
  }, []);



  return (
    <Page
      className={classes.root}
      title="Dashboard"
      style={{ 
  backgroundImage: `url(${process.env.PUBLIC_URL + '/bgMain.jpg'})`,

   'background-repeat': 'no-repeat',
  'background-size':'cover',
   'background-position': 'center',
   
}}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
          
        >
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <Budget transfer = {transfer}/>
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <TotalCustomers payment = {payment}/>
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xl={4}
            xs={12}
          >
            <TasksProgress receivables = {receivables}/>
          </Grid>
      
         
         
       
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
