import React , { useState,useEffect }  from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import ProfileDetails from './ProfileDetails';
import { baseUrl } from "../../../include.js";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Invoice = () => {
  const classes = useStyles();
 const [customers,setCustomer] = useState([]);
 const [details,setDetails] = useState([]);
 const [teller,setTeller] = useState([]);
   useEffect(() => {
        const fetchMode = () =>
          /*fetch(baseUrl+"/receipt",{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            })*/
          axios
        .get(baseUrl+"/receipt",{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            }).then(items=>{
            
              const receipt = items.data.receipt;
                const detail = items.data.detail;
                const tellername = items.data.name;
               setCustomer(receipt);
                setDetails(detail)
                setTeller(tellername) 
              
            })
        fetchMode()
      }, []);
   
  return (
    <Page
      className={classes.root}
      title="Account"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
       
          <Grid
            item
            lg={10}
            md={12}
            xs={12}
          >
            <ProfileDetails customers={customers} teller={teller} details={details}/>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Invoice;
