import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    address: {
      country: 'USA',
      state: 'West Virginia',
      city: 'Parkersburg',
      street: '2849 Fulton Street'
    },
    avatarUrl: '/static/images/avatars/avatar_3.png',
    status: 'Manager',
    email: 'jules@eazidon.com',
    name: 'King Jules',
     branch: 'Buduburam',
    country: 'Ghana'
  }
];
