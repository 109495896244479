import React, { useState,useEffect } from 'react';
import {BrowserRouter, useHistory} from 'react-router';
import axios from 'axios';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
   useNavigate,
    useLocation
  } from "react-router-dom";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box,Button,Card,CardContent,CardHeader, Divider,Grid,TextField,makeStyles} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from 'react-select';
import { baseUrl } from "../../../include.js";
import { selectStyles } from "../../../include.js";
const useStyles = makeStyles(() => ({
  root: {


  },
  
}));

function generate(element: React.ReactElement) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}




const TransferMoney = ({ className, ...rest }) => {
  const classes = useStyles();
    const initialFormState = { transaction_id: null, transaction_code: '',sender_name:'',sender_phone:'',receiver_name:'',receiver_phone:'',sending_teller:'',receiving_teller:'',amount_paid:'',currency:'',transaction_fee:'',transaction_amount:'',due_amount:'', transfer_method_one:'',transfer_method_two:'',tamount_one:'',tamount_two:'',t_cur_one:'',
           t_cur_two:'',
           transfer_cur_one:'',
           transfer_cur_two:''}

    const [transfer, setTransfer] = useState(initialFormState)

    const navigate = useNavigate();
    const [currency1, setCurrency1] = useState([]);
    const [currency2, setCurrency2] = useState([]);
    const [currency3, setCurrency3] = useState([]);
    const [currency4, setCurrency4] = useState([]);
    const [currency5, setCurrency5] = useState([]);
    const [currency6, setCurrency6] = useState([]);
    const [currencys, setCurrencys] = useState([]);

     const [receiver, setReceiver] = useState([]);
    const [receivers, setReceivers] = useState([]);

    const [code, setCode] = useState("");

  


   const handleChange = event => {
        const { name, value } = event.target
        setTransfer({ ...transfer, [name]: value })
      }

  const handleUser=(selectedValue)=>{
            console.log(selectedValue.value); 
            setReceiver(selectedValue.value);
        }

    const handleCurrency1=(curvalue)=>{
            console.log(curvalue.value); 
            setCurrency1(curvalue.value);
        }


    const handleCurrency2=(curvalue)=>{
            console.log(curvalue.value); 
            setCurrency2(curvalue.value);
        }

    const handleCurrency3=(curvalue)=>{
            console.log(curvalue.value); 
            setCurrency3(curvalue.value);
        }


    const handleCurrency4=(curvalue)=>{
            console.log(curvalue.value); 
            setCurrency4(curvalue.value);
        }

    const handleCurrency5=(curvalue)=>{
            console.log(curvalue.value); 
            setCurrency5(curvalue.value);
        }


    const handleCurrency6=(curvalue)=>{
            console.log(curvalue.value); 
            setCurrency6(curvalue.value);
        }

    
      const addTransfer = transfer => {
       
        let obj = {
           transaction_code:code,
           sender_name:transfer.sender_name,
           sender_phone:transfer.sender_phone,
           receiver_name:transfer.receiver_name,
           receiver_phone:transfer.receiver_phone,
           sending_teller:transfer.sending_teller,
           receiving_teller:receiver,
           amount_paid:transfer.amount_paid,
           currency:currency1,
           transaction_amount:transfer.transaction_amount,
           due_amount:transfer.due_amount,
           transaction_fee:transfer.transaction_fee,
           t_cur_one:currency2,
           t_cur_two:currency3,
           transfer_cur_one:currency4,
           transfer_cur_two:currency5,
           transfer_method_one:transfer.transfer_method_one,
           transfer_method_two:transfer.transfer_method_two,
           tamount_one:transfer.tamount_one,
           tamount_two:transfer.tamount_two
      
        };
      
         axios
          .post(baseUrl+"/transfer-money", obj,{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            })
          .then(response => {
           
            setTransfer(initialFormState);
            alert("Information Saved");
            
            navigate("/app/invoice");
            
          })
          .catch(error => {
            console.log(error);
          });
      };

      const CurrencyMain=()=> {  
                return (currencys.map(data => ({ label: data.currency_name, value: data.currency_id})))  
        }

        const ReceiverMain=()=> {  
                return (receivers.map(data => ({ label: data.name, value: data.user_id})))  
        }
  
  useEffect(() => {
    async function getCurrency() {
      const response = await fetch(baseUrl+"/currency",{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            });
      const currencyList = await response.json();
      //console.log(localStorage.usertoken);
      setCurrencys(currencyList);
    }
     async function getUser() {
      const response = await fetch(baseUrl+"/userBranch",{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            });
      const userList = await response.json();
      //console.log(localStorage.usertoken);
      setReceivers(userList);
    }

    async function getCode() {
      const response = await fetch(baseUrl+"/transfer-money",{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            });
      const codeList = await response.json();
      //console.log(localStorage.usertoken);
      setCode(codeList);
    }
    getCurrency();
     getUser();
     getCode();
  }, []);

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
       onSubmit={event => {
                                    event.preventDefault();
                                    addTransfer(transfer)
                                    
                                  }}
    >
      <Card>
        <CardHeader
          title="Transaction Details"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                size="small"
                label="Transfer Code"
                name="transaction_code"
                 id="transaction_code"
                onChange={handleChange}
                required
                type="disabled"
                value={code}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Select
       id="receiving_teller"
         name="receiving_teller"
       placeholder="Select User"
        onChange={handleUser}
       styles={selectStyles}
        options={ReceiverMain()}

      />
            </Grid>
           
            
           
            
          </Grid>
        </CardContent>
       
      
        <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
               size="small"
                label="Sender's Name"
                name="sender_name"
                onChange={handleChange}
                required
                value={transfer.sender_name}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                size="small"
                label="Sender's Phone"
                name="sender_phone"
                 id="sender_phone"
                onChange={handleChange}
                required
                value={transfer.sender_phone}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
           
            
           
            
          </Grid>
        </CardContent>
        
        <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
               size="small"
                label="Receiver Name"
                name="receiver_name"
                onChange={handleChange}
                required
                value={transfer.receiver_name}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Receiver Phone"
                name="receiver_phone"
                onChange={handleChange}
                required
                size="small"
                value={transfer.receiver_phone}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
           
            
           
            
          </Grid>
        </CardContent>
        <Divider />
        <CardHeader
          title="Payment Details"
        />
        <Divider />
        <CardContent>
          
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.title}>
           Transfer Amount
          </Typography>
         <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
               size="small"
                label="Transfer Amount"
                name="amount_paid"
                 id="amount_paid"
                onChange={handleChange}
                required
                value={transfer.amount_paid}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
       <Select
       id="currency"
  name="currency"
       placeholder="Select Cuurency"
       onChange={handleCurrency1}
       styles={selectStyles}
        options={CurrencyMain()}
      />
             
    
            </Grid>
           
            
           
            
          </Grid>


        </CardContent>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.title}>
           Transfer Fees
          </Typography>
          <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
               size="small"
                label="Transfer Fee"
                name="transaction_amount"
                id="transaction_amount"
                onChange={handleChange}
                required
                value={transfer.transaction_amount}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Select
  id="tamount"
       placeholder="Select Cuurency"
         onChange={handleCurrency2}
       styles={selectStyles}
        options={CurrencyMain()}
        
      />

            </Grid>
           
            
           
            
          </Grid>
        </CardContent>
        </Grid>
      </Grid>

       <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.title}>
            Payment Methods
          </Typography>
         <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
               size="small"
                label="Payment Method 1"
                name="transfer_method_one"
                onChange={handleChange}
                required
                value={transfer.transfer_method_one}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Payment Method 2"
                name="transfer_method_two"
                onChange={handleChange}
                required
                size="small"
                value={transfer.transfer_method_two}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
           
            
           
            
          </Grid>


        </CardContent>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.title}>
            Payment Methods (Transaction Fee)
          </Typography>
          <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
               size="small"
                label="Payment Method 1"
                name="tamount_one"
                onChange={handleChange}
                required
                value={transfer.tamount_one}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Payment Method 2"
                name="tamount_two"
                onChange={handleChange}
                required
                size="small"
                value={transfer.tamount_two}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />

            </Grid>
           
            
           
            
          </Grid>
        </CardContent>
        </Grid>


      </Grid>


 <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.title}>
            
          </Typography>
         <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <Select
  id="transfer_cur_one"
      placeholder="Select Currency"
       onChange={handleCurrency3}
       styles={selectStyles}
        options={CurrencyMain()}

      
      />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Select
  id="transfer_cur_two"
       placeholder="Select Cuurency"
       onChange={handleCurrency4}
       
        options={CurrencyMain()}
      
      />
            </Grid>
           
            
           
            
          </Grid>


        </CardContent>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.title}>
            
          </Typography>
          <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <Select
  id="t_cur_one"
       placeholder="Select Cuurency"
       onChange={handleCurrency5}
       styles={selectStyles}
        options={CurrencyMain()}
       
      />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Select
  id="t_cur_two"
       placeholder="Select Cuurency"
       onChange={handleCurrency6}
       styles={selectStyles}
        options={CurrencyMain()}
      
      />

            </Grid>
           
            
           
            
          </Grid>
        </CardContent>
        </Grid>

        
      </Grid>


        </CardContent>
        <Divider />

        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

TransferMoney.propTypes = {
  className: PropTypes.string
};

export default TransferMoney;
