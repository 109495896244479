import React , { useState,useEffect }  from 'react';
import axios from 'axios';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Container,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import getInitials from 'src/utils/getInitials';
import {  useNavigate, useParams, Link  } from 'react-router-dom';
import { baseUrl } from "../../../include.js";

const useStyles = makeStyles(() => ({
  root: {}
}));

const PaymentReceipt = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
let { id } = useParams();

 const [customers,setCustomer] = useState([]);
 const [details,setDetails] = useState([]);



   useEffect(() => {
        const fetchMode = () =>
         
          axios
        .get(baseUrl+"/payment-receipt/"+id,{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            }).then(items=>{
            
              const receipt = items.data.receipt;
                const detail = items.data.detail;
                const tellername = items.data.name;
               setCustomer(receipt);
                setDetails(detail)
               
              
            })
        fetchMode()
      }, []);


 


  return (
    <Page
      className={classes.root}
      title="Account"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
       
          <Grid
            item
            lg={10}
            md={12}
            xs={12}
          >
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader=""
          title="UMF Transfer"
        />
        <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
 <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={12}
          >
            <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
             Sender Details
            </Typography>

             <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
            Sender Name : {customers.sender_name}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
             Sender Phone : {customers.sender_phone}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
            Sending Teller : {customers.name}
            </Typography>
            
          </Grid>
          
        </Grid>
      
      </CardContent>
          </Grid>


           <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={12}
          >
            <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
             Receiver Details
            </Typography>

             <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
             Receiver Name : {customers.receiver_name}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              Receiver Phone : {customers.receiver_phone}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
            Receiving Teller : {customers.name}
            </Typography>
            
          </Grid>
          
        </Grid>
      
      </CardContent>
          </Grid>
        
       
        </Grid>
      </Container>
      <Divider />
      
    </Card>
        <Divider />
        <CardContent>
          <PerfectScrollbar>
        <Box minWidth={750}>
          <Table>
            <TableHead>
              <TableRow>
              
                
                <TableCell>
                 Details
                </TableCell>
                <TableCell>
                 Amount
                </TableCell>
               
                <TableCell>
                  Payment Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
             
                <TableRow
                  hover
                  key={customers.id}
                  
                >
                 
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                       Amount Paid
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                      {customers.symbol} {customers.amount_paid}
                  </TableCell>
                  
                 
                  <TableCell>
                   {details.payment_method_1 } {details.payment_method_one } || {details.payment_method_2 } {details.payment_method_two }
                  </TableCell>
                </TableRow>
              
                <TableRow
                  hover
                  key={customers.id}
                  
                >
                 
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                       Amount Due
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                   <Box
                      alignItems="center"
                      display="flex"
                    >
                      
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {customers.symbol} {customers.due_amount}
                      </Typography>
                    </Box>
                  </TableCell>
                  
                 
                  
                </TableRow>
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => window.print()}
          >
            Print Invoice
          </Button>
        </Box>
      </Card>
    </form>
     </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

PaymentReceipt.propTypes = {
  className: PropTypes.string
};

export default PaymentReceipt;
