import React, { useState } from 'react';
import {BrowserRouter, useHistory} from 'react-router';
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useNavigate,  useLocation
  } from "react-router-dom";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import Page from 'src/components/Page';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { CardHeader,Divider, Avatar, Box,Card,Container,CardContent,TextField,InputAdornment,
   Button,Checkbox,Table,TableBody,TableCell,TableHead,TablePagination,TableRow,Typography,
  makeStyles,Grid} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import Select from 'react-select';
import { baseUrl } from "../../../include.js";
const useStyles = makeStyles((theme) => ({
  root: {
   
    marginRight:'auto',
    maxWidth: '60%'
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const AddCurrency = ({ className, customers, ...rest }) => {
  const classes = useStyles();
 const initialFormState = { currency_id: null,status:'',symbol:'',currency_name:''}

    const [currency, setCurrency] = useState(initialFormState)

   const options = [
  { value: '1', label: 'Active' },
  { value: '2', label: 'Inactive' },

];
const [status, setStatus] = useState(null);
 const navigate = useNavigate();
const handleType=(selectedValue)=>{
            console.log(selectedValue.value); 
            setStatus(selectedValue.value);
        }
   const handleChange = event => {
        const { name, value } = event.target
        setCurrency({ ...currency, [name]: value })
      }

       const addCurrencys = currency => {
       
        let obj = {
          currency_name:currency.currency_name,
           symbol:currency.symbol,
           status:status,
           
        };
      
         axios
          .post(baseUrl+"/create-currency", obj,{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            })
          .then(response => {
           
            setCurrency(initialFormState);
            alert("Information Saved");
            
            navigate("/app/currency");
            
          })
          .catch(error => {
            console.log(error);
          });
      };


  return (

    <Page
      className={classes.root}
      title="Currency"
    >
      <Container maxWidth={false}>
       
        <Box mt={4}>
           <Card
      
    >
     
        <Box minWidth={900}>


 <CardContent>

            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
       onSubmit={event => { event.preventDefault();
                            addCurrencys(currency)}}
    >
      <Card>
        <CardHeader
          title="Add New Currency"
        />
       
     

 <Grid container spacing={2}>  
        <Divider />

         <CardContent>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
               id="currency_name"
                label="Enter Currency Name"
                name="currency_name"
                onChange={handleChange}
                required
                value={currency.currency_name}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Currency Symbol"
                name="symbol"
               id="symbol"
                onChange={handleChange}
                required
                value={currency.symbol}
                variant="outlined"
                InputLabelProps={{
            shrink: true,
          }}
              />
            </Grid>

 <Grid
              item
              md={12}
              xs={12}
            >
               <Select
       
       onChange={handleType}
        options={options}
      />
            </Grid>

             <Grid
              item
              md={12}
              xs={12}
            >
             <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Save Currency
          </Button>
        </Box>
            </Grid>



           
            
           
            
          </Grid>
        </CardContent>

        
        


        </Grid>
      </Card>
    </form>



         </CardContent>
        </Box>



     </Card>
        </Box>
      </Container>
    </Page>
   
  );
};

export default AddCurrency;
