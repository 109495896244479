import React, { useState,useEffect } from 'react';
import {BrowserRouter, useHistory} from 'react-router';
import axios from 'axios';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    Link,
    Redirect,
   useNavigate,
    useLocation
  } from "react-router-dom";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box,Button,Card,CardContent,CardHeader, Divider,Grid,TextField,makeStyles} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from 'react-select';
import { baseUrl } from "../../../include.js";
import { selectStyles } from "../../../include.js";
const useStyles = makeStyles(() => ({
  root: {


  },
  
}));

function generate(element: React.ReactElement) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}




const PayCash = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
let { id } = useParams();

    const initialFormState = { transaction_id: null, payment_method_one:'',payment_method_two:'',payment_cur_one:'',payment_cur_two:''}

       
 const [details,setDetails] = useState([]);
 const [teller,setTeller] = useState([]);

    const [payment, setPayment] = useState(initialFormState)

    const [currency1, setCurrency1] = useState([]);
    const [currency2, setCurrency2] = useState([]);
   
    const [currencys, setCurrencys] = useState([]);

     const [receiver, setReceiver] = useState([]);
    const [receivers, setReceivers] = useState([]);

  


   const handleChange = event => {
        const { name, value } = event.target
        setPayment({ ...payment, [name]: value })
      }

  const handleUser=(selectedValue)=>{
            console.log(selectedValue.value); 
            setReceiver(selectedValue.value);
        }

    const handleCurrency1=(curvalue)=>{
            console.log(curvalue.value); 
            setCurrency1(curvalue.value);
        }


    const handleCurrency2=(curvalue)=>{
            console.log(curvalue.value); 
            setCurrency2(curvalue.value);
        }

   


          const fetchMode = () =>{
         
          axios.get(baseUrl+"/pay-cash/"+id,{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            }).then(items=>{
            
             
                const detail = items.data.transaction;
                const tellername = items.data.name;
              
                setDetails(detail)
                setTeller(tellername) 
              
            });
  }
    
      const addPayment = payment => {
       
        let obj = {
           transaction_code:details.transaction_code,
           sender_name:details.sender_name,
           sender_phone:details.sender_phone,
           receiver_name:details.receiver_name,
           receiver_phone:details.receiver_phone,
           sending_teller:details.sending_teller,
           receiving_teller:receiver,
           amount_paid:details.amount_paid,
           due_amount:details.due_amount,
           payment_cur_one:currency1,
           payment_cur_two:currency2,
           payment_method_one:payment.payment_method_one,
           payment_method_two:payment.payment_method_two,
        };
      
         axios
          .post(baseUrl+"/pay-cash/"+details.transaction_id, obj,{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            })
          .then(response => {
           
            setPayment(initialFormState);
            alert("Information Saved");
            
            navigate('/app/payment-receipt/'+details.transaction_id);
            
          })
          .catch(error => {
            console.log(error);
          });
      };

      const CurrencyMain=()=> {  
                return (currencys.map(data => ({ label: data.currency_name, value: data.currency_id})))  
        }

        
  
  useEffect(() => {
    async function getCurrency() {
      const response = await fetch(baseUrl+"/currency",{
                headers: { Authorization: `Bearer ${localStorage.usertoken}`}
            });
      const currencyList = await response.json();
      //console.log(localStorage.usertoken);
      setCurrencys(currencyList);
    }
    

    getCurrency();
    fetchMode();
  }, []);

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
       onSubmit={event => {
                                    event.preventDefault();
                                    addPayment(payment)
                                    
                                  }}
    >
      <Card>
        <CardHeader
          title="Transaction Details"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
             <Typography variant="h6" className={classes.title}>
          Transaction Code
          </Typography>
              <TextField
                fullWidth
                size="small"
                label=""
                name="transaction_code"
                 id="transaction_code"
               
                required
                type="disabled"
                value={details.transaction_code}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
             <Typography variant="h6" className={classes.title}>
          Sending Teller
          </Typography>
              <TextField
                fullWidth
                size="small"
                label=""
                name="sending_teller"
                 id="sending_teller"
                onChange={handleChange}
                required
                type="disabled"
                value={details.name}
                variant="outlined"
              />
            </Grid>
           
            
           
            
          </Grid>
        </CardContent>
       
      
        <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
             <Typography variant="h6" className={classes.title}>
           Sender Name
          </Typography>
              <TextField
                fullWidth
               size="small"
                label=""
                 type="disabled"
                name="sender_name"
                id="sender_name"
                onChange={handleChange}
                required
                value={details.sender_name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
             <Typography variant="h6" className={classes.title}>
          Sender Phone
          </Typography>
              <TextField
                fullWidth
                size="small"
                label=""
                 type="disabled"
                name="sender_phone"
                 id="sender_phone"
                onChange={handleChange}
                required
                value={details.sender_phone}
                variant="outlined"
              />
            </Grid>
           
            
           
            
          </Grid>
        </CardContent>
        
        <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
             <Typography variant="h6" className={classes.title}>
          Receiver Name
          </Typography>
              <TextField
                fullWidth
               size="small"
                label=""
                 type="disabled"
                name="receiver_name"
 id="receiver_name"
                onChange={handleChange}
                required
                value={details.receiver_name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
             <Typography variant="h6" className={classes.title}>
         Receiver Phone
          </Typography>
              <TextField
                fullWidth
                label=""
                 type="disabled"
                name="receiver_phone"
                id="receiver_phone"
                onChange={handleChange}
                required
                size="small"
                value={details.receiver_phone}
                variant="outlined"
              />
            </Grid>
           
            
           
            
          </Grid>
        </CardContent>
        <Divider />
        <CardHeader
          title="Payment Methods"
        />
        <Divider />

        <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" className={classes.title}>
          Due Amount
          </Typography>
         <CardContent>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              md={8}
              xs={12}
            >
              <TextField
                fullWidth
               size="small"
                label=""
                name="amount_paid"
                 id="amount_paid"
                onChange={handleChange}
                required
                value={details.amount_paid}
                variant="outlined"
              />
            </Grid>
           
           
            
          </Grid>


        </CardContent>
        </Grid>
       
      </Grid>




          
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.title}>
           Payment Method 1
          </Typography>
         <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
               size="small"
                label="Enter Amount"
                name="payment_method_one"
                 id="payment_method_one"
                onChange={handleChange}
                required
                value={payment.payment_method_one}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
                 <Select
                 id="currency1"
            name="currency1"
                 placeholder="Select Cuurency"
                 onChange={handleCurrency1}
                 styles={selectStyles}
                  options={CurrencyMain()}
                />
             
    
            </Grid>
           
            
           
            
          </Grid>


        </CardContent>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.title}>
           Payment Method 2
          </Typography>
          <CardContent>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
               size="small"
                label="Enter Amount"
                name="payment_method_two"
                id="payment_method_two"
                onChange={handleChange}
                required = {true}
                value={payment.payment_method_two}
                variant="outlined"
                isRequired="true"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Select
         id="currency2"
       placeholder="Select Cuurency"
         onChange={handleCurrency2}
       styles={selectStyles}
        options={CurrencyMain()}
        
      />

            </Grid>
           
            
           
            
          </Grid>
        </CardContent>
        </Grid>
      </Grid>

     



        </CardContent>
        <Divider />

        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
           Pay Cash
          </Button>
        </Box>
      </Card>
    </form>
  );
};

PayCash.propTypes = {
  className: PropTypes.string
};

export default PayCash;
